.header {
    position: relative;

    @include laptop {
        height: 70vh;
    }

    @include desktop {
        height: 50vh;
    }

    .header__background {
        background-image: url(../images/bg-intro-mobile.svg);
        height: 28.9rem;
        background-position-y: (-$separation*1.15);
        background-size: cover;
        background-repeat: no-repeat;

        @include laptop {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 60%;
            background-image: url(../images/bg-intro-desktop.svg);
            background-position-x: $separation+4;
            background-size: 93rem;
            background-position-y: (-($separation*4)+1);
        }

        @include desktop {
            width: 55%;
        }

        &::after {
            content: '';
            display: block;
            width: 95%;
            height: 100%;
            margin: 0 auto;
            background-repeat: no-repeat;
            background-size: cover;
            background-position-y: -12.9rem;
            
            @include tablet {
                background-position-y: -20rem;
                width: 60%;
            }


            @include laptop {
                width: 100%;
                background-position-y: -11rem;
                background-size: 62rem;
                background-position-x: 32rem;
                height: 61.7rem;
            }

            @include desktop {
                background-position-x: 28rem;
            }
        }
    }
}

.webp {
    .header__background {

        &::after {
            background-image: url(../images/image-mockups.webp);
        }
    }
}

.no-webp {
    .header__background {

        &::after {
            background-image: url(../images/image-mockups.png);
        }
    }
}

.header__content {
    @include laptop {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }
}

.header__text {
    text-align: center;
    margin-top: $separation/1.3;
    margin-bottom: $separation*1.5;

    @include tablet {
        width: 60%;
        margin-left: auto;
        margin-right: auto;
    }

    @include laptop {
        width: 36%;
        text-align: left;
        margin-left: 0;
    }

    p {
        margin: $separation/2.5 0 $separation/1.5 0;
    }
}