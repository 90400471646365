.footer {
    padding: $separation/1.2 0;
    background-color: $colorDarkBlue;
    text-align: center;

    .container {
        width: 70%;

        @include laptop {
            width: 100%;
        }
    }
}

.footer__content {
    @include laptop {
        display: grid;
        gap: $separation/2;
        grid-template-columns: 1fr 2fr 1fr;
        grid-template-areas: "footer__brand footer__nav footer__btn"
                            "social__medias footer__nav footer__copyright";
    }
}

.footer__brand {
    @include laptop {
        text-align: left;
        grid-area: footer__brand;
    }
}

.social__medias {
    margin: $separation/1.8 auto $separation/1.8 auto;

    @include laptop {
        grid-area: social__medias;
        margin: auto 0 0 0;
    }

    &.container {
        width: 80%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include laptop {
            width: 60%;
            margin-left: 0;
        }
    }

    a {
        display: flex;
    }

    img {
        width: 2.5rem;

        @include laptop {
            width: 2rem;
        }
    }
}

.social__svg {
    path {
        transition: all .2s ease-in-out;
    }
    &:hover {
        path {
            fill: $colorLimeGreen;
        }
    }
}

.footer__nav {
    display: flex;
    flex-direction: column;

    @include laptop {
        grid-area: footer__nav;
        display: grid;
        grid-template-columns: repeat(2, 200px);
        text-align: left;

        a:nth-child(2) {
            grid-column: 1/2;
            grid-row: 2/3;
            margin: auto 0;
        }

        a:nth-child(3) {
            grid-column: 1/2;
            grid-row: 3/4;
            margin: auto 0 0 0;
        }

        a:nth-child(5) {
            margin: auto 0;
        }

        a:nth-child(6) {
            margin: auto 0 0 0;
        }
    }

    .footer__link {
        padding-bottom: $separation/3;
        color: $colorWhite;
        font-weight: $fontLight;
        font-size: 1.5rem;

        @include laptop {
            padding: 0;
            width: fit-content;
            transition: all .2s ease-in-out;
            
            &:hover {
                color: $colorLimeGreen;
            }
        }
    }
}

.footer__btn {
    width: 17rem;
    margin: $separation/2 auto $separation/2 auto;

    @include laptop {
        grid-area: footer__btn;
        margin: 0 0 0 auto;
    }
}

.footer__copyright {
    color: $colorLightGrayishBlue;
    @include laptop {
        grid-area: footer__copyright;
        text-align: right;
    }
}