.backdrop {
    height: 100%;
    position: fixed;
    width: 100%;
    z-index: 60;
    background: rgba(45, 47, 61, 0.9);
    background: -moz-linear-gradient(top, rgba(45, 47, 61, 0.9) 0%, rgba(255, 255, 255, 0.2) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(45, 47, 61, 0.9)), color-stop(100%, rgba(255, 255, 255, 0.2)));
    background: -webkit-linear-gradient(top, rgba(45, 47, 61, 0.9) 0%, rgba(255, 255, 255, 0.2) 100%);
    background: -o-linear-gradient(top, rgba(45, 47, 61, 0.9) 0%, rgba(255, 255, 255, 0.2) 100%);
    background: -ms-linear-gradient(top, rgba(45, 47, 61, 0.9) 0%, rgba(255, 255, 255, 0.2) 100%);
    background: linear-gradient(to bottom, rgba(45, 47, 61, 0.9) 0%, rgba(255, 255, 255, 0.2) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2d2f3d', endColorstr='#ffffff', GradientType=0);
}

.hidden {
    display: none;
}